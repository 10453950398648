import {
	CSSProperties,
	FC,
	useMemo
} from 'react'
import classNames from 'classnames'
import { ICtaSection5 } from '@shared/types/generated/contentful'
import Image from 'next/image'
import { Heading } from '@chakra-ui/react'

const CtaSection5: FC<{ ctaSection5Data: ICtaSection5 }> = ({ ctaSection5Data }) => {
	const imageStyle = useMemo((): CSSProperties => ({
		width: 250,
		height: 250
	}), [])
	if (!ctaSection5Data || !ctaSection5Data.fields) return (
		<></>
	)
	return (
		<div
			className={classNames(
				'relative w-full',
				'justify-center py-12 md:py-20',
			)}
			style={{
				backgroundColor: '#F07E2D'
			}}
		>
			<div
				className={'theContainer'}
			>
				<Heading
					as={'h3'}
					size={'2xl'}
					className={'text-white font-semibold text-center whitespace-pre-line'}
				>
					{ ctaSection5Data.fields.heading }
				</Heading>
				<p
					className={'text-lg mt-4 font-normal text-center text-white'}
				>
					{ ctaSection5Data.fields.description }
				</p>
				<div
					className={'grid items-start grid-cols-1 md:grid-cols-3 gap-10 md:gap-8 mt-10 md:mt-12 items-start'}
				>
					{ ctaSection5Data.fields.features.map((feature, index) => (
						<div
							key={index}
							className={'flex flex-col items-center'}
						>
							{ feature.fields.image && (
								<Image
									src={'https:' + feature.fields.image.fields.file.url + '?fm=webp'}
									width={300}
									height={300}
									style={imageStyle}
									className={'object-cover rounded'}
									alt={feature.fields.image.fields.title}
								/>
							)}
							<h4
								className={'mt-4 text-lg font-semibold text-white'}
							>
								{ feature.fields.title }
							</h4>
							<p
								className={'w-4/5 mt-1 text-center text-white opacity-90'}
							>
								{ feature.fields.description }
							</p>
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export default CtaSection5
