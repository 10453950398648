import { FC } from 'react'
import nextI18NextConfig from '../i18nextConfig'

type LinkRelAlternateContainerProps = {
	route?: string
}

export const LinkRelAlternateContainer: FC<LinkRelAlternateContainerProps> = ({ route }) => {
	const routePath = route ? `/${route}` : ''
	return (
		<>
			{nextI18NextConfig.i18n.locales.map(locale => (
				<link
					key={`https://freshgreens.es/${locale}${routePath}`}
					rel={'alternate'}
					hrefLang={locale}
					href={`https://freshgreens.es/${locale}${routePath}`}
				/>
			))}
		</>
	)
}
