import { FC } from 'react'

type SeoMetaProps = {
	title: string
	metaDescription: string
	image?: string
	keywords?: string[]
	noindex?: boolean
	ldJson?: string
}

export const SeoMeta: FC<SeoMetaProps> = ({
	title,
	metaDescription,
	image,
	keywords,
	noindex,
	ldJson
}) => {
	return (
		<>
			<meta
				name={'title'}
				content={title}
			/>
			<meta
				name={'description'}
				content={metaDescription}
			/>
			<meta
				property={'og:title'}
				content={title}
			/>
			<meta
				property={'og:description'}
				content={metaDescription}
			/>
			{image && (
				<meta
					property={'og:image'}
					content={image}
				/>
			)}
			<meta
				property={'twitter:title'}
				content={title}
			/>
			<meta
				property={'twitter:description'}
				content={metaDescription}
			/>
			{image && (
				<meta
					property={'twitter:url'}
					content={image}
				/>
			)}
			{keywords && (
				<meta
					name={'keywords'}
					property={'keywords'}
					content={keywords.join(', ')}
				/>
			)}
			{noindex && (
				<meta
					name={'robots'}
					content={'noindex'}
				/>
			)}
			{ldJson && (
				<script
					type={'application/ld+json'}
					dangerouslySetInnerHTML={{
						__html: ldJson
					}}
				/>
			)}
		</>
	)
}
