import { BlazeConfig } from 'blaze-slider'
import classNames from 'classnames'
import {
	FC,
	useCallback,
	useEffect,
	useState
} from 'react'
import { useBlazeSlider } from '~/hooks/useBlazeSlider'
import ChevronRight from '~/assets/icons/chevron-right.svg'
import ChevronLeft from '~/assets/icons/chevron-left.svg'
import 'blaze-slider/dist/blaze.css'

const arrowStyle = {
	top: 'calc(50% - 32px)'
}
interface BlazerSliderProps {
  config: BlazeConfig
	className?: string
	hideArrows?: boolean
}

export const BlazerSlider: FC<BlazerSliderProps> = ({
	children,
	config,
	className,
	hideArrows
}) => {
	const [numberOfPages, setNumberOfPages] = useState(0)
	const [pageIndex, setPageIndex] = useState(0)
	const {
		blazeRef,
		blazeSlider
	} = useBlazeSlider(config)

	useEffect(() => {
		const unsubscribe = blazeSlider.current?.onSlide(
			(pageIndex) => {
				setPageIndex(pageIndex)
			}
		)

		return unsubscribe as unknown as () => void
	}, [blazeSlider])

	const getNumberOfDots = useCallback(() => {
		const paginationDots = (blazeRef.current as HTMLDivElement | null)?.querySelectorAll('.blaze-pagination > button')
		setNumberOfPages(paginationDots.length)
	}, [blazeRef])

	useEffect(() => {
		getNumberOfDots()
		const observer = new MutationObserver(() => getNumberOfDots)
		if (!document.body) return
		observer.observe(document.body, {
			subtree: true,
			childList: true
		})

		return () => observer.disconnect()
	}, [getNumberOfDots])

	const paginationCss = `
		.blaze-pagination > button {
			width: 14px;
			height: 14px;
			background-color: #D2FFD6;
		}
		.blaze-pagination > button.active {
			width: 14px;
			height: 14px;
			background-color: #FC5689;
		}
	`

	return (
		<div
			className={classNames(
				'blaze-slider relative',
				className
			)}
			ref={blazeRef}
		>
			<style
				jsx
			>
				{paginationCss}
			</style>
			<div
				className={'blaze-container'}
			>
				{!hideArrows && (
					<div
						className={'hidden md:block'}
					>
						<button
							style={arrowStyle}
							className={classNames(
								'absolute -left-4 blaze-prev z-10 bg-secondary rounded-full w-11 h-11 duration-150',
								pageIndex === 0 && 'opacity-0 pointer-events-none'
							)}
						>
							<ChevronLeft
								className={'m-0 m-auto'}
								stroke={'white'}
								width={34}
								height={34}
								strokeWidth={3}
							/>
						</button>
						<button
							style={arrowStyle}
							className={classNames(
								'absolute -right-4 blaze-next z-10 bg-secondary rounded-full w-11 h-11 duration-150',
								// The -1 is because the index starts at 0
								(pageIndex === (numberOfPages - 1) || numberOfPages === 0) && 'opacity-0 pointer-events-none'
							)}
						>
							<ChevronRight
								className={'m-0 m-auto'}
								stroke={'white'}
								width={34}
								height={34}
								strokeWidth={3}
							/>
						</button>
					</div>
				)}
				<div
					className={'blaze-track-container'}
				>
					<div
						className={'blaze-track'}
					>
						{children}
					</div>
				</div>
				<div
					className={'blaze-pagination flex justify-center space-x-2 mt-4'}
				>
				</div>
			</div>
		</div>
	)
}