import {
	CSSProperties,
	FC,
	useMemo
} from 'react'
import classNames from 'classnames'
import DollarIcon from 'assets/icons/dollar-sign.svg'
import HeartIcon from 'assets/icons/heart.svg'
import GlobeIcon from 'assets/icons/globe.svg'
import { Button } from '@chakra-ui/button'
import { ICtaSection3 } from '@shared/types/generated/contentful'
import Link from 'next/link'
import Image from 'next/image'
import { Heading } from '@chakra-ui/react'

const CtaSection3: FC<{ ctaSection3Data: ICtaSection3 }> = ({ ctaSection3Data }) => {
	const imageStyle = useMemo((): CSSProperties => ({
		objectFit: 'cover',
		objectPosition: 'right'
	}), [])
	if (!ctaSection3Data || !ctaSection3Data.fields) return (
		<></>
	)
	return (
		<div
			className={classNames(
				'relative w-full overflow-clip flex items-center',
				'justify-center py-6 sm:py-8',
			)}
			style={{
				backgroundColor: `${ctaSection3Data.fields.backgroundColor}`
			}}
		>
			<div
				className={'flex flex-col-reverse sm:flex-row items-stretch sm:items-start w-full theContainer py-6'}
			>
				{ ctaSection3Data.fields?.image && (
					<Image
						src={`https:${ctaSection3Data.fields.image.fields.file.url}?fm=webp`}
						width={ctaSection3Data.fields.image.fields.file.details.image?.width}
						height={ctaSection3Data.fields.image.fields.file.details.image?.height}
						className={'rounded-xl h-full w-full lg:w-1/3'}
						style={imageStyle}
						quality={95}
						alt={ctaSection3Data.fields.image.fields.title}
					/>
				)}
				<div
					className={'flex flex-col items-center sm:items-start mb-12 sm:mb-0 lg:w-1/2 pl-6 md:pl-8 xl:pl-16'}
				>
					<Heading
						as={'h3'}
						size={'2xl'}
						className={'text-neutral-700 font-semibold text-left whitespace-pre-line'}
					>
						{ ctaSection3Data.fields.heading }
					</Heading>
					<p
						className={'mt-4 text-left text-neutral-700 font-semibold'}
					>
						{ ctaSection3Data.fields.description }
					</p>
					<div
						className={'flex flex-col items-start w-full mt-8'}
					>
						<div
							className={'flex items-start w-full md:w-auto'}
						>
							<div
								className={'p-3 rounded-full bg-keppel-600'}
							>
								<DollarIcon
									color={'white'}
									width={24}
									height={24}
									strokeWidth={2}
								/>
							</div>
							<p
								className={'flex flex-col items-start text-left ml-4'}
							>
								<span
									className={'text-xl font-semibold text-teal-900'}
								>
									{ ctaSection3Data.fields.ctaBlocks[0].fields.title }
								</span>
								<span
									className={'text-neutral-600 mt-2'}
								>
									{ ctaSection3Data.fields.ctaBlocks[0].fields.description }
								</span>
							</p>
						</div>
						<div
							className={'flex items-start mt-8 w-full md:w-auto'}
						>
							<div
								className={'p-3 rounded-full bg-keppel-600'}
							>
								<HeartIcon
									color={'white'}
									width={24}
									height={24}
									strokeWidth={2}
								/>
							</div>
							<p
								className={'flex flex-col items-start text-left ml-4'}
							>
								<span
									className={'text-xl font-semibold text-teal-900'}
								>
									{ ctaSection3Data.fields.ctaBlocks[1].fields.title }
								</span>
								<span
									className={'text-neutral-600 mt-2'}
								>
									{ ctaSection3Data.fields.ctaBlocks[1].fields.description }
								</span>
							</p>
						</div>
						<div
							className={'flex items-start mt-8 w-full md:w-auto'}
						>
							<div
								className={'p-3 rounded-full bg-keppel-600'}
							>
								<GlobeIcon
									color={'white'}
									width={24}
									height={24}
									strokeWidth={2}
								/>
							</div>
							<p
								className={'flex flex-col items-start text-left ml-4'}
							>
								<span
									className={'text-xl font-semibold text-teal-900'}
								>
									{ ctaSection3Data.fields.ctaBlocks[2].fields.title }
								</span>
								<span
									className={'text-neutral-600 mt-2'}
								>
									{ ctaSection3Data.fields.ctaBlocks[2].fields.description }
								</span>
							</p>
						</div>
					</div>
					{ ctaSection3Data.fields.buttonHref && (
						<Link
							href={ctaSection3Data.fields.buttonHref}
							passHref={true}
							className={'mt-6 md:mt-10'}
						>
							<Button
								colorScheme={'primary'}
								size={'lg'}
								variant={'solid'}
							>
								{ ctaSection3Data.fields.buttonText }
							</Button>
						</Link>
					)}
				</div>
			</div>
		</div>
	)
}

export default CtaSection3
