import { FC } from 'react'
import { ISeoMetaTags } from '@shared/types/generated/contentful'
import { SeoMeta } from 'components/SeoMeta'

/*
	Component taking Meta tags model data from Contentful and generating metatags
 */
const SeoMetaTags: FC<{ seoMetaTagsData: ISeoMetaTags }> = ({ seoMetaTagsData }) => {
	return (
		<SeoMeta
			title={seoMetaTagsData.fields.seoTitle}
			metaDescription={seoMetaTagsData.fields.metaDescription}
			image={`https:${seoMetaTagsData.fields.ogImage.fields.file.url}`}
			keywords={seoMetaTagsData.fields.tags}
			noindex={seoMetaTagsData.fields.hidePageFromSearchEngines}
			ldJson={seoMetaTagsData.fields.ldJson}
		/>
	)
}

export default SeoMetaTags
