import {
	useEffect,
	useRef
} from 'react'
import BlazeSlider, { BlazeConfig } from 'blaze-slider'

export const useBlazeSlider = (config: BlazeConfig) => {
	const blazeSlider = useRef<null | BlazeSlider>(null)
	const elRef = useRef()

	useEffect(() => {
		// if not already initialized
		if (!blazeSlider.current) {
			blazeSlider.current = new BlazeSlider(elRef.current as HTMLElement, config)
		}
	}, [config])

	return {
		blazeRef: elRef,
		blazeSlider
	}
}