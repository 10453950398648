import type {
	GetStaticProps,
	GetStaticPropsContext,
	NextPage,
	PreviewData
} from 'next'
import Head from 'next/head'
import contentfulClient from '~/adapters/contentfulClient'
import { getLanguageFromLocale } from '~/helpers/locale'
import {
	IHomepageFields,
	ISeoMetaTags
} from '@shared/types/generated/contentful'
import RenderContentfulSection from 'components/RenderContentfulSection'
import dynamic from 'next/dynamic'
import getSeoMetaTags from '~/adapters/contentful/getSeoMetaTags'
import SeoMetaTags from 'components/SeoMetaTags'
import { LinkRelAlternateContainer } from '~/components/LinkRelAlernateContainer'
import { ParsedUrlQuery } from 'querystring'
import { GlobalStaticProps } from '~/interfaces/GlobalStaticProps.interface'
import { withGlobalStaticProps } from '~/helpers/withGlobalStaticProps'

const DynamicRenderContentfulSection = dynamic(
	() => import('components/RenderContentfulSection'),
	{
		ssr: false
	}
)
type HomeProps = GlobalStaticProps & {
	seoMetaTagsData?: ISeoMetaTags;
	homepageData?: IHomepageFields;
}

const Home: NextPage<HomeProps> = (props) => {
	return (
		<div>
			<Head>
				{props.seoMetaTagsData && (
					<>
						<title>{ props.seoMetaTagsData.fields.seoTitle }</title>
						<SeoMetaTags
							seoMetaTagsData={props.seoMetaTagsData}
						/>
					</>
				)}
				<meta
					name={'facebook-domain-verification'}
					content={'oa7aayccnzbtz1ej0wb0gkzavdtnvp'}
				/>
				<link
					rel={'icon'}
					href={'/favicon.ico'}
				/>
				<LinkRelAlternateContainer />
			</Head>
			<main
				className={'flex w-full flex-1 flex-col items-center justify-center text-center'}
			>
				{ props.homepageData?.body.map((section, index) => (
					index === 0 ? (
						<RenderContentfulSection
							key={`homepage-section-${section.sys.id}`}
							section={section}
						/>
					) : (
						<DynamicRenderContentfulSection
							key={`homepage-section-${section.sys.id}`}
							section={section}
						/>
					)
				))}
			</main>
		</div>
	)
}

export const getStaticProps: GetStaticProps = withGlobalStaticProps(async (pageContext: GetStaticPropsContext<ParsedUrlQuery, PreviewData>) => {
	const locale = pageContext.locale
	const { items } = await contentfulClient.getEntries({
		content_type: 'homepage',
		'fields.hiddenTitle': 'Homepage',
		locale: getLanguageFromLocale(locale),
		include: 4
	})

	return {
		props: {
			seoMetaTagsData: await getSeoMetaTags(locale, '/'),
			homepageData: items[0].fields as unknown as IHomepageFields
		}
	}
})

export default Home
