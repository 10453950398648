import {
	CSSProperties,
	FC,
	useMemo
} from 'react'
import classNames from 'classnames'
import { ICtaSection4 } from '@shared/types/generated/contentful'
import Link from 'next/link'
import { Button } from '@chakra-ui/button'
import Image from 'next/image'
import { Heading } from '@chakra-ui/react'
import { BlazerSlider } from 'components/BlazerSlider'

const CtaSection4: FC<{ ctaSection4Data: ICtaSection4 }> = ({ ctaSection4Data: ctaSection4Data }) => {
	const imageStyle = useMemo((): CSSProperties => ({
		width: 230,
		height: 230
	}), [])
	const blazerConfig = {
		all: {
			loop: false,
			enableAutoplay: false,
			transitionDuration: 200,
			slidesToShow: 1,
			draggable: true
		},
		'(min-width: 1024px)': {
			slidesToShow: 3
		}
	}
	if (!ctaSection4Data || !ctaSection4Data.fields) return (
		<></>
	)
	return (
		<div
			className={classNames(
				'relative w-full',
				'justify-center py-10 md:py-16',
			)}
			style={{
				backgroundColor: ctaSection4Data.fields.backgroundColor || '#F2E3CC'
			}}
		>
			<div
				className={'theContainer flex flex-col'}
			>
				{ ctaSection4Data.fields.preHeading && (
					<p
						className={'text-center font-semibold text-primary-500 mb-3 text-2xl'}
					>
						{ ctaSection4Data.fields.preHeading }
					</p>
				)}
				<Heading
					as={'h3'}
					size={'2xl'}
					lineHeight={1.1}
					className={'text-secondaryDark max-w-2xl mx-auto text-center whitespace-pre-line'}
				>
					{ ctaSection4Data.fields.heading }
				</Heading>
				<div
					className={classNames(
						'mt-10 py-6 px-3 sm:py-12 sm:px-12 bg-white rounded-humongous'
					)}
				>
					<p
						className={'mx-auto w-3/4 sm:w-full text-xl font-semibold text-center text-secondaryDark'}
					>
						{ctaSection4Data.fields.description}
					</p>
					<BlazerSlider
						className={'mt-5 w-full'}
						config={blazerConfig}
						hideArrows
					>
						{ctaSection4Data.fields.features.map((feature, index) => (
							<div
								key={`cta-section-4-feature-${feature.sys.id}`}
								className={'flex flex-col items-center'}
							>
								{ feature.fields.image && (
									<Image
										src={'https:' + feature.fields.image.fields.file.url + '?fm=webp'}
										width={320}
										height={320}
										style={imageStyle}
										className={'object-contain'}
										alt={feature.fields.image.fields.title}
									/>
								)}
								<p
									className={'text-xl font-bold text-secondaryDark'}
								>
									{ feature.fields.title }
								</p>
								<p
									className={'mx-auto w-3/4 sm:w-full mt-2 text-center text-secondaryDark'}
								>
									{ feature.fields.description }
								</p>
							</div>
						))}
					</BlazerSlider>
				</div>
				{ ctaSection4Data.fields.ctaButtonHref && ctaSection4Data.fields.ctaButtonText && (
					<Link
						href={ctaSection4Data.fields.ctaButtonHref}
						passHref={true}
						className={'mt-6'}
					>
						<Button
							className={'heading-font'}
							colorScheme={'primary'}
							size={'lg'}
							variant={'solid'}
						>
							{ ctaSection4Data.fields.ctaButtonText }
						</Button>
					</Link>
				)}
			</div>
		</div>
	)
}

export default CtaSection4
