import {
	CSSProperties,
	FC,
	useMemo
} from 'react'
import classNames from 'classnames'
import Image from 'next/image'
import { Button } from '@chakra-ui/button'
import { IDeliveryInfoSection } from '@shared/types/generated/contentful'
import Link from 'next/link'
import Color from 'color'
import { Heading } from '@chakra-ui/react'

const DeliveryInfoSection: FC<{ deliveryInfoSectionData: IDeliveryInfoSection }> = ({ deliveryInfoSectionData }) => {
	const imageStyle = useMemo((): CSSProperties => ({
		objectFit: 'contain'
	}), [])
	const headingStyle = useMemo(() => (
		deliveryInfoSectionData?.fields?.backgroundColor && {
			backgroundColor: `${deliveryInfoSectionData.fields.backgroundColor}`,
			color: `${Color(deliveryInfoSectionData.fields.backgroundColor).darken(0.75)}`
		}
	), [deliveryInfoSectionData?.fields?.backgroundColor])
	if (!deliveryInfoSectionData || !deliveryInfoSectionData.fields) return (
		<></>
	)
	return (
		<section
			className={classNames(
				'relative w-full overflow-clip flex flex-col lg:flex-row items-center',
				'justify-center pt-12 pb-12'
			)}
			style={{
				backgroundColor: `${deliveryInfoSectionData.fields.backgroundColor}`
			}}
		>
			<div
				className={classNames(
					'theContainer z-10 w-full flex items-center justify-center flex-col',
					deliveryInfoSectionData.fields.imageOnTheLeft ? 'sm:flex-row-reverse':'sm:flex-row'
				)}
			>
				<div
					className={classNames(
						'mb-6 sm:mb-0 text-center sm:text-left w-full lg:w-3/5 flex flex-col items-center lg:items-start',
						deliveryInfoSectionData.fields.imageOnTheLeft && 'sm:pl-12'
					)}
				>
					<Heading
						as={'h3'}
						size={'2xl'}
						className={'font-semibold text-warm-gray-900 inline-flex'}
						style={headingStyle}
					>
						{ deliveryInfoSectionData.fields.heading }
					</Heading>
					<div
						className={'w-full md:w-4/5 mt-4'}
					>
						<span
							className={'text-left whitespace-pre-line'}
							style={{
								color: Color(deliveryInfoSectionData.fields.backgroundColor).darken(0.7).hex()
							}}
							dangerouslySetInnerHTML={{
								__html: deliveryInfoSectionData.fields.description
							}}
						/>
					</div>
					{ deliveryInfoSectionData.fields.buttonHref && (
						<Link
							className={'mt-4'}
							href={deliveryInfoSectionData.fields.buttonHref}
						>
							<Button
								variant={'solid'}
								colorScheme={'primary'}
								size={'lg'}
							>
								{ deliveryInfoSectionData.fields.buttonText }
							</Button>
						</Link>
					)}
				</div>
				{ deliveryInfoSectionData.fields.image && (
					<div
						className={'w-full lg:w-2/5 flex items-center justify-center'}
					>
						<Image
							src={`https:${deliveryInfoSectionData.fields.image.fields.file.url}?fm=webp`}
							width={deliveryInfoSectionData.fields.image.fields.file.details.image?.width}
							height={deliveryInfoSectionData.fields.image.fields.file.details.image?.height}
							style={imageStyle}
							className={'rounded'}
							quality={95}
							alt={deliveryInfoSectionData.fields.image.fields.title}
						/>
					</div>
				)}
			</div>
		</section>
	)
}

export default DeliveryInfoSection
