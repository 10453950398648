import { FC } from 'react'
import { IThreeStepsSection } from '@shared/types/generated/contentful'
import Image from 'next/image'
import { Heading } from '@chakra-ui/react'
import { BlazerSlider } from '../BlazerSlider'

type ThreeStepsSectionProps = {
	threeStepsSectionData: IThreeStepsSection
}

const ThreeStepsSection: FC<ThreeStepsSectionProps> = ({ threeStepsSectionData }) => {
	const blazerConfig = {
		all: {
			loop: false,
			enableAutoplay: false,
			transitionDuration: 200,
			slidesToShow: 1,
			draggable: true
		},
		'(min-width: 1024px)': {
			slidesToShow: 3
		}
	}

	// Comment to help search:
	// Eat Healthy In 3 Steps
	return (
		<div
			className={'w-full theContainer mb-16 mt-12'}
		>
			<div
				className={'flex flex-col items-center p-6 sm:p-10 bg-white rounded-3xl'}
			>
				<Heading
					as={'h3'}
					size={{
						base: 'lg',
						sm: '2xl'
					}}
					className={'font-semibold text-center text-palette-darkGreen'}
				>
					{ threeStepsSectionData.fields.title }
				</Heading>
				<BlazerSlider
					className={'mt-7 w-full'}
					config={blazerConfig}
					hideArrows
				>
					<div
						className={'flex flex-col items-center'}
					>
						<Image
							className={'w-44 h-44 object-cover'}
							alt={'Ordering subscription'}
							src={'/three-steps-section/mobile.svg'}
							width={160}
							height={160}
						/>
						<p
							className={'w-56 text-center font-bold text-lg text-black mt-4'}
						>
							{ threeStepsSectionData.fields.titles?.[0] }
						</p>
						<p
							className={'mt-1 w-64 text-lg text-center text-black leading-tight'}
						>
							{ threeStepsSectionData.fields.descriptions?.[0] }
						</p>
					</div>
					<div
						className={'flex flex-col items-center'}
					>
						<Image
							className={'w-44 h-44 object-cover'}
							alt={'Cooking the food'}
							src={'/three-steps-section/cooking.svg'}
							width={160}
							height={160}
						/>
						<p
							className={'w-56 text-center font-bold text-lg text-black mt-4'}
						>
							{ threeStepsSectionData.fields.titles?.[1] }
						</p>
						<p
							className={'mt-1 w-64 text-lg text-center text-black leading-tight'}
						>
							{ threeStepsSectionData.fields.descriptions?.[1] }
						</p>
					</div>
					<div
						className={'flex flex-col items-center'}
					>
						<Image
							className={'w-44 h-44 object-cover'}
							alt={'Microwaving the food'}
							src={'/three-steps-section/plate.svg'}
							width={160}
							height={160}
						/>
						<p
							className={'w-56 text-center font-bold text-lg text-black mt-4'}
						>
							{ threeStepsSectionData.fields.titles?.[2] }
						</p>
						<p
							className={'mt-1 w-64 text-lg text-center text-black leading-tight'}
						>
							{ threeStepsSectionData.fields.descriptions?.[2] }
						</p>
					</div>
				</BlazerSlider>
			</div>
		</div>
	)
}

export default ThreeStepsSection
