import { FC } from 'react'
import classNames from 'classnames'
import ArrowRight from 'assets/icons/arrow-right.svg'
import Image from 'next/image'
import { IFaqSection } from '@shared/types/generated/contentful'
import Link from 'next/link'
import { Heading } from '@chakra-ui/react'

const ReasonsToJoin: FC<{ faqSectionData: IFaqSection }> = ({ faqSectionData }) => {
	if (!faqSectionData || !faqSectionData.fields) return (
		<></>
	)
	return (
		<section
			className={classNames(
				'relative w-full overflow-clip',
				'bg-cyan-900 py-12 md:py-24 lg:py-32'
			)}
			id={'faq'}
		>
			<Heading
				as={'h3'}
				size={'2xl'}
				className={'font-semibold text-white theContainer text-center'}
			>
				{ faqSectionData.fields.heading }
			</Heading>
			<div
				className={'mt-10 md:mt-24 theContainer grid sm:grid-cols-2 grid-rows-4 sm:grid-rows-2 gap-4 md:gap-10'}
			>
				{ faqSectionData.fields.questionsAnswers.map((qa, index) => (
					<div
						key={`faq-question-${index}`}
						className={'text-white text-left flex'}
					>
						<div
							className={'pr-3'}
						>
							<div
								className={classNames(
									'mt-1 w-8 h-8 bg-emerald-500 rounded-full',
									'flex items-center justify-center'
								)}
							>
								<ArrowRight
									width={24}
									height={24}
									stroke={'white'}
								/>
							</div>
						</div>
						<div>
							<h4
								className={'text-xl md:text-2xl font-semibold'}
							>
								{ qa.fields.title }
							</h4>
							<p
								className={'mt-3 md:mt-5 text-neutral-400 leading-loose'}
							>
								{ qa.fields.description }
							</p>
						</div>
					</div>
				))}
			</div>
			{ faqSectionData.fields.buttonHref && (
				<Link
					href={faqSectionData.fields.buttonHref}
					passHref={true}
					className={'md:mt-6'}
				>
					<button
						className={classNames(
							'mx-auto flex items-center justify-center',
							'text-white px-6 py-3',
							'hover:opacity-75 duration-100 text-lg rounded-md',
							'active:opacity-50'
						)}
						style={{
							backgroundColor: faqSectionData.fields.buttonColor
						}}
					>
						{ faqSectionData.fields.buttonIcon && (
							<Image
								src={`https:${faqSectionData.fields.buttonIcon.fields.file.url}?fm=webp`}
								alt={faqSectionData.fields.buttonIcon.fields.title}
								width={25}
								height={25}
								quality={90}
							/>
						)}
						<span
							className={'ml-2'}
						>
							{ faqSectionData.fields.buttonText }
						</span>
					</button>
				</Link>
			)}
		</section>
	)
}

export default ReasonsToJoin
