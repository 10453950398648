'use client'

import { FC } from 'react'
import classNames from 'classnames'
import Image from 'next/image'
import 'react-multi-carousel/lib/styles.css'
import { IMealsCarouselSection } from '@shared/types/generated/contentful'
import {
	Button,
	Heading
} from '@chakra-ui/react'
import { BlazerSlider } from '../BlazerSlider'
import Link from 'next/link'
import { useRecoilValueLoadable } from 'recoil'
import { upcomingSetsOfMealsState } from '~/store/atoms/customer/upcomingSetsOfMealsState'
import { filterNewFunnelMeals } from '~/functions/src/shared/helpers/filterNewFunnelMeals'

const MealsCarousel: FC<{
	mealsCarouselSectionData: IMealsCarouselSection
	hideCta?: boolean
}> = ({
	mealsCarouselSectionData,
	hideCta = false
}) => {
	const setOfMealsLoadableValue = useRecoilValueLoadable(upcomingSetsOfMealsState)
	if (
		setOfMealsLoadableValue.state !== 'hasValue' ||
		setOfMealsLoadableValue.contents.length === 0
	) return
	const setOfMeals= setOfMealsLoadableValue.state === 'hasValue' ? setOfMealsLoadableValue.contents[0].fields : null
	if (!mealsCarouselSectionData || !mealsCarouselSectionData.fields) return (
		<></>
	)

	return (
		<div
			className={'mx-auto w-full py-10 md:py-16 bg-palette-champagne'}
		>
			<div
				className={'flex flex-col items-center w-full theContainer'}
			>
				<Heading
					as={'h3'}
					size={'2xl'}
					className={'font-semibold text-palette-darkGreen text-center'}
				>
					{ mealsCarouselSectionData.fields.heading }
				</Heading>
				<p
					className={'text-lg sm:text-xl mt-3 text-center'}
				>
					{ mealsCarouselSectionData.fields.description }
				</p>
				{setOfMeals && (
					<BlazerSlider
						className={'mt-12 w-full'}
						config={{
							all: {
								slidesToShow: 1
							},
							'(min-width: 480px)': {
								slidesToShow: 2
							},
							'(min-width: 768px)': {
								slidesToShow: 3
							}
						}}
					>
						{filterNewFunnelMeals(setOfMeals.meals).map((meal, index) => (
							<div
								className={'px-0.5'}
								key={`meal-carousel-image-${index}`}
							>
								<div
									className={classNames(
										'relative bg-white rounded-4xl overflow-hidden px-4 pt-4 pb-8 h-full',
									)}
								>
									<Image
										src={`https:${meal.fields.image?.fields.file.url}?fm=webp`}
										alt={meal.fields.image?.fields.title}
										width={meal.fields.image?.fields.file.details.image?.width}
										height={meal.fields.image?.fields.file.details.image?.height}
										quality={95}
										className={'w-full rounded-2xl'}
										style={{
											aspectRatio: '1/1',
											objectFit: 'cover'
										}}
									/>
									<Heading
										className={'text-center mt-3 text-secondaryDark'}
										size={'md'}
									>
										{meal.fields.title}
									</Heading>
								</div>
							</div>
						))}
					</BlazerSlider>
				)}
				{!hideCta && (
					<Link
						href={'/our-menu'}
						className={'mt-10 max-w-[18rem] w-full'}
					>
						<Button
							className={'heading-font w-full'}
							colorScheme={'secondary'}
							size={'lg'}
						>
							{mealsCarouselSectionData.fields.ctaButtonText}
						</Button>
					</Link>
				)}
			</div>
		</div>
	)
}

export default MealsCarousel
