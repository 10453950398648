import classNames from 'classnames'
import Image from 'next/image'
import { IHomepageHeader } from '@shared/types/generated/contentful'
import {
	CSSProperties,
	FC,
	useMemo
} from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Heading } from '@chakra-ui/react'

const imageStyle: CSSProperties = {
	objectFit: 'cover'
}
const HomepageHeader: FC<{ homepageHeaderData: IHomepageHeader }> = ({ homepageHeaderData }) => {
	const router = useRouter()
	const ctaButtonLink = useMemo(() => ({
		pathname: homepageHeaderData?.fields?.ctaButtonHref,
		query: router.query
	}), [homepageHeaderData?.fields?.ctaButtonHref, router.query])
	if (!homepageHeaderData || !homepageHeaderData.fields) return (
		<></>
	)
	const {
		heading,
		headingColor,
		description,
		background,
		ctaButtonText,
		ctaButtonHref,
		features,
		backgroundBlurHash,
		reviewsImage
	} = homepageHeaderData.fields

	return (
		<div
			className={'w-full flex flex-col lg:flex-row relative'}
		>
			<div
				className={'theContainer w-full'}
			>
				<div
					className={'flex flex-col items-center lg:items-start pt-32 sm:pt-36 lg:pt-44 w-full lg:w-1/2'}
				>
					<Heading
						as={'h1'}
						size={{
							base: '2xl',
							sm: '3xl',
							xl: '4xl'
						}}
						className={'text-palette-darkGreen lg:text-left whitespace-pre-line sm:pr-3'}
					>
						{ heading }
					</Heading>
					<p
						className={'text-palette-darkGreen mt-4 sm:mt-8 text-lg sm:text-xl lg:text-left whitespace-pre-line'}
					>
						{ description }
					</p>
					{ctaButtonHref && (
						<Link
							href={ctaButtonLink}
							passHref={true}
							className={'mt-6 sm:mt-8'}
						>
							<button
								className={classNames(
									'bg-primary text-white text-lg sm:text-2xl px-8 py-3 rounded-full heading-font',
									'hover:opacity-75 active:opacity-50 duration-150 uppercase'
								)}
							>
								{ ctaButtonText }
							</button>
						</Link>
					)}
					{reviewsImage && (
						<Image
							className={'mt-3'}
							style={{
								maxWidth: '210px'
							}}
							src={`https:${reviewsImage.fields.file.url}?fm=webp`}
							width={reviewsImage.fields.file.details.image?.width}
							height={reviewsImage.fields.file.details.image?.height}
							alt={reviewsImage.fields.title}
							priority
						/>
					)}
				</div>
			</div>
			{background.fields.file.url && (
				<>
					<Image
						src={`https:${background.fields.file.url}?fm=webp`}
						blurDataURL={backgroundBlurHash}
						placeholder={backgroundBlurHash ? 'blur' : 'empty'}
						style={imageStyle}
						width={background.fields.file.details.image?.width}
						height={background.fields.file.details.image?.height}
						quality={96}
						className={'hidden lg:block object-left absolute top-0 right-0 w-1/2 rounded-bl-3xl h-full'}
						alt={background.fields.title}
						priority
					/>
					<div
						className={'lg:hidden theContainer--no-padding-right-mobile theContainer mt-8'}
					>
						<Image
							src={`https:${background.fields.file.url}?fm=webp`}
							blurDataURL={backgroundBlurHash}
							placeholder={backgroundBlurHash ? 'blur' : 'empty'}
							style={imageStyle}
							width={background.fields.file.details.image?.width}
							height={background.fields.file.details.image?.height}
							quality={96}
							className={'rounded-l-3xl md:rounded-3xl'}
							alt={background.fields.title}
							priority
						/>
					</div>
				</>
			)}
		</div>
	)
}

export default HomepageHeader
