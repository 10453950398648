import {
	CSSProperties,
	FC,
	useMemo
} from 'react'
import Image from 'next/image'
import { ICtaSection2 } from '@shared/types/generated/contentful'
import { Heading } from '@chakra-ui/react'

const CtaSection2: FC<{ ctaSection2Data: ICtaSection2 }> = ({ ctaSection2Data }) => {
	const imageStyle = useMemo((): CSSProperties => ({
		objectFit: 'cover'
	}), [])
	if (!ctaSection2Data || !ctaSection2Data.fields) return (
		<></>
	)

	return (
		<section
			className={'flex flex-col md:flex-row items-stretch md:items-start w-full theContainer py-12 md:py-24'}
		>
			<div
				className={'flex w-full md:w-1/2 md:pr-16 xl:pr-28'}
			>
				<div
					className={'flex flex-col items-stretch w-1/2'}
				>
					{ ctaSection2Data.fields.images[0] && (
						<div
							className={'rounded-xl overflow-hidden w-full h-64 md:h-72 relative'}
						>
							<Image
								src={`https:${ctaSection2Data.fields.images[0].fields.file.url}?fm=webp`}
								fill={true}
								style={imageStyle}
								quality={95}
								alt={ctaSection2Data.fields.images[0].fields.title}
							/>
						</div>
					)}
					{ ctaSection2Data.fields.images[2] && (
						<div
							className={'rounded-xl overflow-hidden w-full h-36 relative mt-4'}
						>
							<Image
								src={`https:${ctaSection2Data.fields.images[2].fields.file.url}?fm=webp`}
								fill={true}
								style={imageStyle}
								quality={95}
								alt={ctaSection2Data.fields.images[2].fields.title}
							/>
						</div>
					)}
				</div>
				<div
					className={'flex flex-col items-stretch w-1/2 ml-4 pt-16'}
				>
					{ ctaSection2Data.fields.images[1] && (
						<div
							className={'rounded-xl overflow-hidden w-full h-40 relative'}
						>
							<Image
								src={`https:${ctaSection2Data.fields.images[1].fields.file.url}?fm=webp`}
								fill={true}
								style={imageStyle}
								quality={95}
								alt={ctaSection2Data.fields.images[1].fields.title}
							/>
						</div>
					)}
					{ ctaSection2Data.fields.images[3] && (
						<div
							className={'rounded-xl overflow-hidden w-full h-64 md:h-72 relative mt-4'}
						>
							<Image
								src={`https:${ctaSection2Data.fields.images[3].fields.file.url}?fm=webp`}
								fill={true}
								style={imageStyle}
								quality={95}
								alt={ctaSection2Data.fields.images[3].fields.title}
							/>
						</div>
					)}
				</div>
			</div>
			<div
				className={'flex flex-col items-start md:w-1/2 md:pl-12 py-6'}
			>
				<Heading
					as={'h3'}
					size={'2xl'}
					className={'font-semibold text-teal-900 text-left'}
				>
					{ ctaSection2Data.fields.heading }
				</Heading>
				{ ctaSection2Data.fields.ctaBlocks.map((block, index) => (
					<div
						className={'flex items-start mt-10 md:mt-16'}
						key={`ctaSection2-block-${index}`}
					>
						{ block.fields.image && (
							<div
								className={'w-14 flex-shrink-0'}
							>
								<Image
									src={`https:${block.fields.image.fields.file.url}?fm=webp`}
									height={block.fields.image.fields.file.details.image?.height}
									width={block.fields.image.fields.file.details.image?.width}
									layout={'responsive'}
									quality={95}
									alt={block.fields.image.fields.title}
								/>
							</div>
						)}
						<p
							className={'flex flex-col items-start text-left ml-4'}
						>
							<span
								className={'text-xl font-semibold text-teal-900'}
							>
								{ block.fields.title }
							</span>
							<span
								className={'text-neutral-600 mt-1'}
							>
								{ block.fields.description }
							</span>
						</p>
					</div>
				))}
			</div>
		</section>
	)
}

export default CtaSection2
