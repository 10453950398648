import { selector } from 'recoil'
import contentfulClient from '~/adapters/contentfulClient'
import getCurrentMoment from '@shared/helpers/getCurrentMoment'
import { ISetOfMeals } from '@shared/types/generated/contentful'
import {
	getRegionFromLocale,
	getLanguageFromLocale
} from '~/helpers/locale'
import { selectedLocaleState } from '../checkout/selectedLocaleState'
import businessConfig from '@shared/businessConfig'

export const upcomingSetsOfMealsState = selector<ISetOfMeals[]>({
	key: 'customer:upcomingSetsOfMealsState',
	get: async ({ get }) => {
		const locale = get(selectedLocaleState)
		if (locale === null) throw new Error('locale empty')
		const entries = await contentfulClient.getEntries({
			content_type: 'setOfMeals',
			order: 'fields.deliveryDate',
			'fields.deliveryDate[gte]': getCurrentMoment().format(businessConfig.contentfulDateFormat),
			'fields.locale': getRegionFromLocale(locale),
			locale: getLanguageFromLocale(locale)
		})

		return entries.items as unknown as ISetOfMeals[]
	}
})
