import { FC } from 'react'
import { IReviewsCarousel } from '@shared/types/generated/contentful'
import Image from 'next/image'
import Link from 'next/link'
import {
	Button,
	Heading
} from '@chakra-ui/react'
import { BlazerSlider } from '../BlazerSlider'

type ReviewsCarouselProps = {
	reviewsCarouselData: IReviewsCarousel
}

const ReviewsCarousel: FC<ReviewsCarouselProps> = ({ reviewsCarouselData }) => {
	const blazerConfig = {
		all: {
			loop: false,
			enableAutoplay: false,
			transitionDuration: 200,
			slidesToShow: 1,
			draggable: true
		},
		'(min-width: 1024px)': {
			slidesToShow: 3
		}
	}

	return (
		<div
			className={'mx-auto w-full py-10 md:py-20 theContainer flex flex-col items-center'}
		>
			<Heading
				as={'h3'}
				size={'2xl'}
				className={'text-palette-darkGreen'}
			>
				{reviewsCarouselData.fields.title}
			</Heading>
			<p
				className={'text-lg sm:text-xl mt-4'}
			>
				{reviewsCarouselData.fields.description}
			</p>
			<Image
				className={'rounded-full mt-12 w-64'}
				src={`https:${reviewsCarouselData.fields.ratingImage.fields.file.url}?fm=webp`}
				alt={reviewsCarouselData.fields.ratingImage.fields.title}
				width={reviewsCarouselData.fields.ratingImage.fields.file.details.image.width}
				height={reviewsCarouselData.fields.ratingImage.fields.file.details.image.height}
				quality={95}
			/>
			<BlazerSlider
				className={'mt-6 w-full'}
				config={blazerConfig}
			>
				{reviewsCarouselData.fields.images?.map((image, index) => (
					<div
						key={index}
					>
						<Image
							className={'object-contain w-full rounded-3xl'}
							src={`https:${image.fields.file.url}?fm=webp`}
							alt={image.fields.title}
							width={image.fields.file.details.image.width}
							height={image.fields.file.details.image.height}
							quality={95}
						/>
					</div>
				))
				}
			</BlazerSlider>
			{reviewsCarouselData.fields.linkHref && (
				<Link
					href={reviewsCarouselData.fields.linkHref}
					className={'max-w-[18rem] w-full mt-10'}
				>
					<Button
						className={'heading-font w-full'}
						colorScheme={'secondary'}
						size={'lg'}
					>
						{reviewsCarouselData.fields.linkText}
					</Button>
				</Link>
			)}
		</div>
	)
}

export default ReviewsCarousel
