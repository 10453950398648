import {
	CSSProperties,
	FC,
	useMemo
} from 'react'
import classNames from 'classnames'
import { IImagesSection } from '@shared/types/generated/contentful'
import { Button } from '@chakra-ui/button'
import Link from 'next/link'
import Image from 'next/image'
import { Heading } from '@chakra-ui/react'

const ImagesSection: FC<{ imagesSectionData: IImagesSection }> = ({ imagesSectionData }) => {
	const imageStyle = useMemo((): CSSProperties => ({
		width: '100%',
		height: '100%'
	}), [])
	if (!imagesSectionData || !imagesSectionData.fields) return (
		<></>
	)
	return (
		<div
			className={classNames(
				'relative w-full',
				'justify-center py-12 md:py-20 flex flex-col',
			)}
		>
			<div
				className={'theContainer'}
			>
				<Heading
					size={'2xl'}
					className={'text-primary-500 font-semibold text-center whitespace-pre-line'}
				>
					{ imagesSectionData.fields.heading }
				</Heading>
				<div
					className={'grid items-start grid-cols-1 md:grid-cols-3 gap-3 md:gap-6 mt-6 md:mt-12 items-center'}
				>
					{ imagesSectionData.fields.images.map((image, index) => (
						<div
							key={index}
							className={'flex flex-col items-center h-full'}
						>
							<Image
								src={'https:' + image.fields.file.url + '?fm=webp'}
								width={image.fields.file.details.image.width}
								height={image.fields.file.details.image.height}
								style={imageStyle}
								className={'object-cover rounded-sm'}
								alt={image.fields.title}
							/>
						</div>
					))}
				</div>
				<Heading
					as={'h3'}
					className={'mx-auto mt-5 md:mt-10 w-4/5 text-center text-neutral-700'}
				>
					{ imagesSectionData.fields.description }
				</Heading>
			</div>
			{ imagesSectionData.fields.ctaButtonHref && imagesSectionData.fields.ctaButtonText && (
				<Link
					className={'mt-4 md:mt-6'}
					href={imagesSectionData.fields.ctaButtonHref}
					passHref={true}
				>
					<Button
						colorScheme={'primary'}
						size={'lg'}
						variant={'solid'}
					>
						{ imagesSectionData.fields.ctaButtonText }
					</Button>
				</Link>
			)}
		</div>
	)
}

export default ImagesSection
