import {
	ICtaSection2,
	ICtaSection3,
	ICtaSection4,
	ICtaSection5,
	IDeliveryInfoSection,
	IFaqSection,
	IHomepageFields,
	IHomepageHeader,
	IImagesSection,
	IMealsCarouselSection,
	IReviewsCarousel,
	IThreeStepsSection
} from '@shared/types/generated/contentful'
import HomepageHeader from 'components/ContentfulSections/HomepageHeader'
import CtaSection2 from 'components/ContentfulSections/CtaSection2'
import CtaSection3 from 'components/ContentfulSections/CtaSection3'
import CtaSection4 from 'components/ContentfulSections/CtaSection4'
import CtaSection5 from 'components/ContentfulSections/CtaSection5'
import DeliveryInfoSection from 'components/ContentfulSections/DeliveryInfoSection'
import FaqSection from 'components/ContentfulSections/FaqSection'
import MealsCarousel from 'components/ContentfulSections/MealsCarousel'
import ImagesSection from 'components/ContentfulSections/ImagesSection'
import ReviewsCarousel from './ContentfulSections/ReviewsCarousel'
import ThreeStepsSection from './ContentfulSections/ThreeStepsSection'

const RenderContentfulSection = (props: { section: IHomepageFields['body'][0]}): JSX.Element => {
	const contentType = props.section.sys.contentType.sys.id
	switch (contentType) {
		case 'homepageHeader':
			return (
				<HomepageHeader
					homepageHeaderData={props.section as IHomepageHeader}
				/>
			)
		case 'ctaSection2':
			return (
				<CtaSection2
					ctaSection2Data={props.section as ICtaSection2}
				/>
			)
		case 'ctaSection3':
			return (
				<CtaSection3
					ctaSection3Data={props.section as ICtaSection3}
				/>
			)
		case 'ctaSection4':
			return (
				<CtaSection4
					ctaSection4Data={props.section as ICtaSection4}
				/>
			)
		case 'ctaSection5':
			return (
				<CtaSection5
					ctaSection5Data={props.section as ICtaSection5}
				/>
			)
		case 'imagesSection':
			return (
				<ImagesSection
					imagesSectionData={props.section as IImagesSection}
				/>
			)
		case 'deliveryInfoSection':
			return (
				<DeliveryInfoSection
					deliveryInfoSectionData={props.section as IDeliveryInfoSection}
				/>
			)
		case 'faqSection':
			return (
				<FaqSection
					faqSectionData={props.section as IFaqSection}
				/>
			)
		case 'mealsCarouselSection':
			return (
				<MealsCarousel
					mealsCarouselSectionData={props.section as IMealsCarouselSection}
				/>
			)
		case 'reviewsCarousel':
			return (
				<ReviewsCarousel
					reviewsCarouselData={props.section as IReviewsCarousel}
				/>
			)
		case 'threeStepsSection':
			return (
				<ThreeStepsSection
					threeStepsSectionData={props.section as IThreeStepsSection}
				/>
			)
	}
	return (
		<></>
	)
}

export default RenderContentfulSection
